.firnContainer__container {
    padding: 3vh 8vw;
    font-family: 'Roc Grotesk';
    max-width: 1200px;
    margin: 0 auto;
    color: #161615;
    font-size: 1.1em;
    display: flex;
    flex-direction: column;
}

.firnContainer__intro {
    text-align: center;
    padding: 40px 20px;
    background-color: #EBE3DF;
}

.firnContainer__intro h1 {
    font-size: 2.8em;
    color: #A46648;
    margin-bottom: 20px;
}

.firnContainer__intro p {
    font-size: 1.25em;
    margin-top: 20px;
}

.firnContainer__video {
    text-align: center;
    margin-top: 40px;
}

.firnContainer__video h2 {
    font-size: 2em;
    color: #A46648;
    margin-bottom: 20px;
}

.firnContainer__video video {
    width: 100%;
    max-width: 800px;
    height: auto;
}

.firnContainer__features {
    margin-top: 40px;
}

.firnContainer__features h2 {
    font-size: 2em;
    color: #A46648;
    margin-bottom: 20px;
}

.firnContainer__features li {
    margin-top: 10px;
}

.firnContainer__image {
    text-align: center;
    margin-top: 40px;
}

.firnContainer__image img {
    max-width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .firnContainer__intro h1 {
        font-size: 2.5em;
    }

    .firnContainer__intro p {
        font-size: 1.1em;
    }

    .firnContainer__video h2 {
        font-size: 1.5em;
    }

    .firnContainer__features h2 {
        font-size: 1.5em;
    }
}
