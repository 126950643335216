.cookiePage__body {
    background-color: #ffffff;
    color: #161615;
    font-family: 'Roc Grotesk', sans-serif;
    padding: 4.2328vh 15.625vw;
}

.cookiePage__body h1, h2 {
    color: #A46648;
}

.cookiePage__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.cookiePage__header h1 {
    margin: 0;
}

.cookiePage__last-updated {
    font-size: 0.9em;
    color: #555;
    text-align: right;
}

.cookiePage__section {
    margin-bottom: 20px;
}

.cookie-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.cookie-table th,
.cookie-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.cookie-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.cookiePage__footer {
    text-align: center;
    margin-top: 30px;
}

.cookiePage__body a {
    text-decoration: none;
    color: #A46648;
}

.cookiePage__body a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .cookiePage__body {
        padding: 4.2328vh 5vw;
    }

    .cookie-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 10px;
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }

    .cookie-table th,
    .cookie-table td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
    }

    .cookie-table th {
        background-color: #f4f4f4;
        font-weight: bold;
    }

    .cookie-table td, .cookie-table th {
        width: 25%;
    }
}

