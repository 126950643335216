.newsDetailPage__body {
    background-color: #ffffff;
    color: #161615;
    font-size: 14px;
    font-family: 'Roc Grotesk', sans-serif;
    padding: 4.2328vh 7.8125vw;
}

.newsDetailPage__title {
    font-size: 36px;
    color: #A46648;
    margin: 0;
}

.newsDetailPage__text {
    margin-top: 20px;
    font-size: 16px;
    line-height: 1.5;
}

.newsDetailPage__content {
    margin-top: 20px;
}

.newsDetailPage__images {
    margin-top: 20px;
}

.newsDetailPage__mainImage {
    width: 100%;
    height: auto;
    max-height: 500px;
    object-fit: contain;
}

.newsDetailPage__additionalImages {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.newsDetailPage__additionalImage {
    width: calc(33.33% - 10px);
    height: auto;
    object-fit: cover;
    max-height: 250px;
}

.newsDetailPage__hashtags {
    margin-top: 10px;
    font-size: 16px;
    color: #A46648;
}