.admindashboard__signals__container {
  padding: 20px;
  max-width: 900px;
  margin: 15px auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.signal-header {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-bottom: 10px;
}

hr {
  border: 1px solid #ddd;
  margin-bottom: 20px;
}

.SignalInput {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.SignalInputForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.SignalInputFields {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
}

.Signalsbtn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.btn-color-red {
  background-color: rgb(207, 41, 41);
  color: white;
}

.btn-color-green {
  background-color: #4caf50;
  color: white;
}

.btn-color-red:hover {
  background-color: rgb(129, 23, 23);
}

.btn-color-green:hover {
  background-color: #45a049;
}

.SignalInputTextArea {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.SignalInputTextAreaIn {
  width: 100%;
  height: 120px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.signal-format p {
  font-family: monospace;
  margin: 5px 0;
}

.signal-controls {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.SignalDisplay {
  margin-top: 20px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.SignalDisplayItem {
  padding-left: 10px;
}

.message-color-red {
  color: red;
}

.message-color-green {
  color: lightgreen;
}

.signal-form-field {
  margin-bottom: 15px;
}