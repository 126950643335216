.contactPage__body {
    background-color: #f9f9f9;
    font-family: 'Roc Grotesk';
}

.contactPage__grid {
    padding: 2rem 0;
    display: grid;
    grid-template-columns: repeat(3, 28.646vw);
    gap: 25px;
    justify-content: center;
}

.contactPage__segment {
    background-color: #fff;
    width: 28.646vw;
    height: 28.646vw;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contactPage__segment--route {
    padding: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contactPage__segment--details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 20px;
    padding: 40px;
}

.contactPage__header {
    padding: 5vh 0;
    border-bottom: 1px solid #ccc;
}

.contactPage__header h2 {
    font-size: 2rem;
    color: #A46648;
    margin: 0;
}

.contactPage__header p {
    font-size: 1.2rem;
    color: #161615;
}

.contactPage__segment--details address {
    font-style: normal;
    line-height: 1.8;
    text-align: left;
    padding: 2vh 0;
    font-size: 20px;
}

.contactPage__segment--details i {
    margin-right: 8px;
    color: #A46648;
}

.contactPage__segment--details a {
    color: #161615;
    text-decoration: none;
}

.contactPage__segment--details a:hover {
    color: #A46648;
}

.contactPage__segment--founders h2 {
    text-align: center;
    font-size: 2rem;
    color: #A46648;
    margin-bottom: 1rem;
}

.contactPage__founder {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}

.contactPage__founder__info {
    margin: 0 25px;
}

.contactPage__founder img {
    width: 10.417vw;
    height: 10.417vw;
    border-radius: 5%;
}

.contactPage__founder h3 {
    margin: 0;
    font-size: 1.5rem;
    color: #000000B5;
}

.contactPage__founder p {
    font-size: 1rem;
    color: #161615;
    flex-grow: 1;
    font-style: italic;
    line-height: 1.5;
    margin: 0;
}

@media screen and (max-width: 1400px) {
    .contactPage__grid {
        padding: 2rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        gap: 25px;
        justify-content: center;
    }

    .contactPage__grid>.contactPage__segment:nth-child(3) {
        grid-column: span 2;
    }

    .contactPage__segment {
        width: 100%;
        height: 100%;
    }

    .contactPage__founder img {
        width: auto;
        height: 230px;
    }
}

@media screen and (max-width: 768px) {
    .contactPage__grid {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        padding: 20px;
    }

    .contactPage__grid>.contactPage__segment:nth-child(3) {
        grid-column: span 1;
    }

    .contactPage__header {
        padding: 0;
    }

    .contactPage__segment--details p,
    .contactPage__segment--details address {
        font-size: 1rem;
    }

    .contactPage__founder {
        display: flex;
        flex-direction: column;
    }

    .contactPage__founder img {
        margin-bottom: 1.5rem;
    }
}