.dashboard__savings__container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: column nowrap;
}

.dashboard__savings__header {
    height: 60px;

    display: flex;
    align-items: center;

    font-weight: bold;
    font-size: 1.3rem;

    margin-left: 50px;
}

.dashboard__savings__content {
    width: 100%;
    height: 100%;

    display: flex;
}

.dashboard__savings__overdrive__container {
    width: 48%;
    height: 100%;

    margin-top: 50px;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.dashboard__savings__overdrive__header {
    font-weight: bold;
    font-size: 1.1rem;

    margin-bottom: 25px;
}

.dashboard__savings__overdrive__meter__container {
    height: 150px;
    width: 100%;
    max-width: 240px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard__savings__overdrive__value {
    margin-top: 10px;
}

.dashboard__savings__underdevelopment__container__darkmode {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #2c2c2c; /* Light grey background */
    border: 2px dashed #6c757d; /* Dashed border for emphasis */
    border-radius: 8px;
}

.dashboard__savings__underdevelopment__message__darkmode {
    font-size: 1.1rem;
    font-weight: bold;
    color: #6c757d; /* Muted grey text */
    text-align: center;
    padding: 20px;
}

.dashboard__savings__underdevelopment__container__lightmode {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    background-color: #D9D9D9; /* Light background */
    border: 2px dotted #b1b1b1; /* Solid border for emphasis */
    border-radius: 8px;
}

.dashboard__savings__underdevelopment__message__lightmode {
    font-size: 1.1rem;
    font-weight: bold;
    color: #343a40; /* Dark gray text for contrast */
    text-align: center;
    padding: 20px;
}

.dashboard__savings__imbalance {
    font-weight: bold;
    margin: 10px;
}

.dashboard__savings__retry__button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 30px;
    cursor: pointer;
    transition: 'background-color 0.3s, transform 0.3';
    margin-top: 10px;
}

.dashboard__savings__retry__button:hover {
    background-color: #45a049;
    transform: scale(1.05);
}

.dashboard__savings__retry__button:active {
    background-color: #388e3c;
}