.Dashboard-Body-Overview-Container {

    width: calc(100vw - 50px);

}

.Dashboard-Body-Overview-Rows {
    display: flex;
    flex-flow: column nowrap;
    font-family: 'JetBrains';
}

.Dashboard-Body-Overview-Row {
    height: 450px;
    width: calc(100vw - 65px);

    display: flex;
    flex-flow: row nowrap;
}

.Dashboard-Body-Overview-Row-Element-Container {
    border-radius: 10px;
    margin: 5px;
}

.Body-First-Row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
}


.Dashboard-Body-Overview-Row-Element-Container.Other {
    width: 65%;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;

    padding: 10px 0px;
}

.Dashboard-Body-Overview-Row-Element-Container.Battery {
    min-width: 400px;
}

.Dashboard-Body-Overview-Other-Element-Container {
    width: 30%;
    height: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
}

.Dashboard-Body-Overview-Other-Element {
    height: 20%;

}

.Dashboard-Body-Overview-Other-Element.Titel {
    font-size: 2.5rem;
    text-align: center;
    font-weight: bold;
} 

.Dashboard-Body-Overview-Other-Element-Data {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
}

.Dashboard-Body-Overview-Other-Element-Data-Container {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.7rem;

}

.Dashboard-Body-Overview-Other-Element-Data.Grid {
    display: flex;

    align-items: center;
    justify-content: center;
}

.Dashboard-Body-Overview-Row-Element-Container.Battery {
    display: flex;
    flex-flow: column nowrap;
}

.Dashboard-Body-Overview-Row-Element-Battery-Container {
    display: flex;

    justify-content: center;

    align-items: center;
}

.Dashboard-Body-Overview-Row-Element-Battery-Object-Bottom {
    position: relative;
    height: 75px;
    width: 300px;

    border-radius: 10px;

}

.Dashboard-Body-Overview-Row-Element-Battery-Object-Top {

    height: 20px;
    width: 10px;

    margin-left: -3px;

    border-radius: 0px 10px 10px 0px;
}

.Dashboard-Body-Overview-Row-Element-Object-Container {
    display: flex;

    margin-top: 30px;

    flex-flow: column nowrap;
}

.Dashboard-Body-Overview-Row-Element-Battery-Object-Bottom.Percentage {

    display: flex;
    flex-flow: row nowrap;


    height: 50px;

    margin-left: -2px;

    border: none;

}

.Dashboard-Body-Overview-Row-Element-Battery-Container.Percentage {

    display: flex;
    
    height: 50px;

    margin-left: 5px;

    /* justify-content: center; */
}

.Dashboard-Body-Overview-Row-Element-Battery-Object-Bottom-Indicator {
    width: 10px;
    height: 20px;

    border-radius: 0px 0px 10px 10px;

    background-color: white;
}

.Dashboard-Body-Overview-Row-Element-Battery-Object-Bottom-Indicator-Container {
    display: flex;
    flex-flow: column nowrap;

    align-items: center;

    font-size: 1.2rem;
    font-weight: bold;
}

.Dashboard-Body-Overview-Row-Element-Battery-Object-Bottom-Indicator-Pushback {
    width: 100%;
}

.dashboard__body__overview__row__element__battery__object__filler {
    height: 100%;

    border-radius: 5px;
}

.dashboard__body__overview__row__element__battery__object__filler_arrow_animation {
    width: 0;
    height: 0;

    position: absolute;

    top: 50%;
    transform: translateY(-50%);

    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #3a7e3d;

    animation: fadeInOut 1.5s infinite;
    animation-delay: 0s;
}

.dashboard__body__overview__row__element__battery__object__filler_arrow_animation1 {
    width: 0;
    height: 0;

    position: absolute;

    top: 50%;
    transform: translateY(-50%); 

    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #3a7e3d;

    animation: fadeInOut 1.5s infinite;
    animation-delay: 0.3s;
}

.dashboard__body__overview__row__element__battery__object__filler_arrow_animationred {
    width: 0;
    height: 0;

    position: absolute;

    top: 50%;
    transform: translateY(-50%);

    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid #D83530;

    animation: fadeInOut 3s infinite;
    animation-delay: 0.3s;
}

.dashboard__body__overview__row__element__battery__object__filler_arrow_animation1red {
    width: 0;
    height: 0;

    position: absolute;

    top: 50%;
    transform: translateY(-50%); 

    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid #D83530;

    animation: fadeInOut 3s infinite;
    animation-delay: 0s;
}

@keyframes fadeInOut {
    0% { opacity: 1; }   /* Fully visible */
    50% { opacity: 0; }  /* Fully transparent */
    100% { opacity: 1; } /* Fully visible again */
}

.Dashboard-Body-Overview-Row-Element-Battery-Data-Container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;

    margin: 0 auto 0 auto;
    width: 80%;
}

.Dashboard-Body-Overview-Row-Element-Battery-Data-Stats-Container {
    display: flex;
    justify-content: space-evenly;

    font-size: 1.2rem;
    font-weight: bold;
}

.Dashboard-Body-Overview-Row-Element-Battery-Data-Graph-Container {
    height: 100%;
    width: 100%;

    font-size: 1.2rem;
    font-weight: bold;

    display: flex;
    flex-flow: column nowrap;

}

.Dashboard-Body-Overview-Row-Element-Battery-Data-Graph {
    width: 100%;
    height: 100%;
}

.Dashboard-Body-Overview-Row-Element-Container.Graphs {
    width: 100%;
    padding: 5px;
}

.Dashboard-Body-Overview-Row-Element-Container.Flows {
    min-width: 400px;
    max-width: 400px;

    min-height: 400px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.Dashboard-Body-Overview-Row-Element-Container.Extra {
    min-width: 400px;
    max-width: 400px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.Dashboard-Body-Overview-Row-Element-Container.Profit {
    min-width: 450px;
    width: 100vw;

    /* visibility: hidden; */
}

.dashboard__overview__Battery__box__graph__titles {
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.dashboard__overview__Battery__box__graph__titles__item {
    opacity: 0.5;
    cursor: pointer;
}

.dashboard__overview__Battery__box__graph__titles__item--active {
    opacity: 1;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-gif {
    width: 150px;
}
