/* Card Styles */
.card {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 24px;
  }
  
  /* CardContent Styles */
  .card-content {
    padding: 24px;
  }
  
  /* Button Styles */
  .button {
    padding: 12px 16px;
    border-radius: 8px;
    background-color: #3b82f6; /* blue-600 */
    color: white;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #2563eb; /* blue-700 */
  }
  
  .button:focus {
    outline: none;
    box-shadow: 0 0 0 2px #3b82f6; /* blue-600 */
  }
  
  /* Input Styles */
  .input {
    width: 100%;
    padding: 10px;
    border: 1px solid #d1d5db; /* gray-300 */
    border-radius: 8px;
    transition: border-color 0.3s ease;
  }
  
  .input:focus {
    outline: none;
    border-color: #3b82f6; /* blue-500 */
    box-shadow: 0 0 0 2px #3b82f6; /* blue-500 */
  }
  
  /* Additional layout styles */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f3f4f6; /* gray-100 */
    padding: 16px;
  }
  
  .heading {
    font-size: 1.25rem; /* text-xl */
    font-weight: bold;
    margin-bottom: 16px;
  }
  
  .subheading {
    margin-bottom: 16px;
    color: #4b5563; /* gray-600 */
  }
  
  .success-message {
    color: #16a34a; /* green-600 */
  }