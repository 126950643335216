.Dashboard-Side-Navbar-container {
    width: 50px;
    min-width: 50px;
    min-height: calc(100vh - 75px);

    background-color: #2C2F33;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
}

.Dashboard-Side-Navbar-Item {
    position: relative;

    margin-top: 10px;
    cursor: pointer;
}

.icon-text {
    position: absolute;
    
    /* color: #00A3FF; */
    color: white;
    font-size: 0.9rem;

    background-color: black;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 5px;
    border-radius: 25px;

    top: 0;
    left: 45px;
}

.Dashboard-Side-Navbar-Item div {
    visibility: hidden;
}

.Dashboard-Side-Navbar-Item:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.Dashboard-Side-Navbar-Item.selected {
    font-weight: bold;
    color: blue; /* Example style for selected item */
}

.icon-text.icon-hovered {
    visibility: visible;
}

.Dashboard-Side-Navbar-Item-Bottom {
    margin-top: auto;
    margin-bottom: 10px;
}