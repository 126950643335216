.Dashboard-Calendar-Container {
    width: 1000px;

    display: flex;
    flex-flow: column nowrap;

    padding: 0px 50px;

    margin: 10px;

    border-radius: 50px;
    font-family: Poppins;
    font-weight: bold;
    font-size: 1.2rem;
}

.Dashboard-Calendar-Date-Container {
    width: 100%;
    height: 50px;

    position: relative;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.Dashboard-Calendar-Apply-Button {
    justify-self: flex-end;

    position: absolute;
    right: 0;
}

.Dashboard-Calendar-MonthView-Container {
    height: calc(100% - 50px);
    width: 100%;


    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.Dashboard-Calendar-DayView-Container {
    width: 13%;
    height: 100%;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;

}

.Dashboard-Calendar-DayView-Box-Container {
    width: 125px;
    height: 125px;

    border-radius: 20px;
    margin-bottom: 10px;

    
}

.Dashboard-Calendar-DayView-Box-Date-Container {
    height: 20%;
    width: 100%;

    font-size: 0.9rem;
    text-align: center;

    font-weight: 400;
}

.Dashboard-Calendar-DayView-Box-Container.inActive {
    opacity: 0.1;
}

.Dashboard-Calendar-DayView-Box-Input-Container {
    height: 80%;
    width: 100%;

    display: flex;
    flex-flow: row nowrap;
}

.Dashboard-Calendar-DayView-Box-Input-Divider {
    height: 100%;
    width: 3px;
    margin-left: 20px;
}

.Dashboard-Calendar-DayView-Box-Input-Box-Container {

    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: column nowrap;
}

.Dashboard-Calendar-DayView-Box-Input-Box-Slider-Container {
    height: 50%;
    width: 100%;

    display: flex;
    flex-flow: row nowrap;
}

.Dashboard-Calendar-DayView-Box-Input-Box-Slider {
    width: 50%;
    height: 100%; 


    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
}

.Dashboard-Calendar-DayView-Box-Input-Box-Slider.Percent {
    width: 50%;
    height: 100%;

    font-weight: 400;
    font-size: 0.8rem;
    
}

.Dashboard-Calendar-DayView-Box-Input-Box-Slider.Button {
    width: 50%;
    height: 100%;

}

.Dashboard-Calendar-DayView-Box-Input-Box-Slider-Rect {
    width: 45px;
    height: 25px;

    cursor: pointer;

    position: relative;

    border-radius: 50px;

    transition: background-color 0.4s; /* Add transition effect */
}

.Dashboard-Calendar-DayView-Box-Input-Box-Slider-Circle {
    aspect-ratio: 1;
    height: 19px;

    position: absolute;

    border-radius: 100%;

    transition: transform 0.4s; /* Add transition effect */

    top: 3px;
    left: 3px;

    background-color: white;
}
.Dashboard-Calendar-DayView-Box-Input-Box-Slider-Rect.inactive {
    background-color: #F88C8C;
}

.Dashboard-Calendar-DayView-Box-Input-Box-Slider-Rect.active {
    background-color: #4CAF50;
}

.Dashboard-Calendar-DayView-Box-Input-Box-Slider-Circle.moved {
    transform: translateX(20px)
}

.Dashboard-Calendar-Date-Before {
    width: 50px;
    height: 100%;

    cursor: pointer;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}

.Dashboard-Calendar-Date-Arrow {

    width: 3px;
    height: 20px;
}

.Dashboard-Calendar-Date-Arrow.Up {
    transform: rotate(45deg);
    margin-bottom:-8px;
}

.Dashboard-Calendar-Date-Arrow.Down {
    transform: rotate(-45deg);
}

.Dashboard-Calendar-Date-After {
    width: 50px;
    height: 100%;

    transform: rotate(180deg);

    cursor: pointer;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}

.Dashboard-Calendar-Date-Month {
    width: 100px;
    text-align: center;
}

.Dashboard-Calendar-Date-Year {
    width: 100px;
    text-align: center;
}