@media only screen and (min-width: 768px) and (max-width: 991px) {
    .offerte__app__container {
        width: 723px;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .offerte__app__container {
        width: 933px;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

@media only screen and (max-width: 767px) {
    .offerte__app__container {
        width: auto !important;
        margin-left: 1em !important;
        margin-right: 1em !important;
    }
}

@media only screen and (min-width: 1200px) {
    .offerte__app__container {
        width: 1127px;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}
