.powerblockPage__container {
    padding: 3vh 8vw;
    font-family: 'Roc Grotesk';
    max-width: 1200px;
    margin: 0 auto;
    color: #161615;
    font-size: 1.1em;
}

.powerblockPage__container h1,
.powerblockPage__container h2 {
    color: #A46648;
    margin-bottom: 20px;
}

.powerblockPage__introduction {
    text-align: center;
    padding: 40px 20px;
    background-color: #EBE3DF;
}

.powerblockPage__introduction h1 {
    font-size: 2.8em;
}

.powerblockPage__introduction p {
    font-size: 1.25em;
    margin-top: 20px;
}

.powerblockPage__content {
    display: flex;
    align-items: center;
    /* Vertical alignment */
    justify-content: space-between;
    gap: 20px;
}

.powerblockPage__text-content {
    width: 60%;
}

.powerblockPage__text {
    margin-bottom: 20px;
}

.powerblockPage__features {
    margin-top: 20px;
}

.powerblockPage__features ul {
    list-style-type: disc;
    margin-left: 20px;
}

.powerblockPage__features li {
    margin-top: 10px;
}

.powerblockPage__image-block {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.powerblockPage__image-block img {
    max-width: 100%;
    height: auto;
}

.powerblockPage__open-image {
    margin-top: 20px;
}

.powerblockPage__open-image img {
    width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .powerblockPage__container {
        padding: 10px;
    }

    .powerblockPage__content {
        flex-direction: column;
        align-items: center;
    }

    .powerblockPage__text-content,
    .powerblockPage__image-block {
        width: 100%;
    }

    .powerblockPage__image-block img {
        max-width: 60%;
        height: auto;
    }
}