.simulationrequestslist__container {
    padding: 20px;
}

.simulation-requests-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.simulation-requests-table th,
.simulation-requests-table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
}

.simulation-requests-table th {
    background-color: #f1f1f1;
}

.simulation-requests-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.simulationrequestslist__container button {
    background-color: #2d3237;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
}

.simulationrequestslist__container button:hover {
    background-color: #0056b3;
}

.error-message {
    color: red;
    font-weight: bold;
}

.statistics {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 15px;
}

.statistics div {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    width: 30%;
    text-align: center;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.statistics div h4 {
    margin: 0 0 10px;
    font-size: 20px;
    color: #555;
}

.statistics div p {
    font-size: 32px;
    margin: 0;
    font-weight: bold;
    color: #333;
}

.statistics .subtitle {
    font-size: 0.75rem;
    color: #bbb;
}

.log-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}

.log-section {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
}

.log-section h4 {
    margin: 0;
    font-size: 1.2em;
}

.log-section ul {
    list-style-type: none;
    padding-left: 0;
}

.log-section ul li {
    padding: 5px;
    border-bottom: 1px solid #ddd;
}

.log-section ul li:last-child {
    border-bottom: none;
}