.homepage__oursolutions__container {
    width: 100%;
    font-family: 'Roc Grotesk';
}

.homepage__oursolutions__header__container {
    width: 100%;
}

.homepage__oursolutions__header {
    width: 750px;
    text-align: center;
    margin: auto;
    margin-top: 100px;
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
}

.homepage__oursolutions__header__pretitle {
    font-size: 14px;
    color: #A46648;
    letter-spacing: 2.66px;
}

.homepage__oursolutions__header__title {
    font-size: 42px;
    font-weight: bold;
    color: #A46648;
}

.homepage__oursolutions__header__desc {
    font-size: 16px;
    color: #161615;
}

.homepage__oursolutions__content__container {
    margin-top: 90px;
    margin-bottom: 75px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
}

.homepage__oursolutions__content__item {
    width: 400px;
}

.homepage__oursolutions__content__header {
    width: 100%;
    height: 240px;
    background-color: rgba(164, 103, 72, 0.12);
    position: relative;
    margin-bottom: 100px;
}

.homepage__oursolutions__item__text__header {
    font-size: 24px;
    font-weight: bold;
    color: #A46648;
    margin-bottom: 20px;
}

.homepage__oursolutions__item__text__desc {
    font-size: 16px;
    color: #161615;
    font-weight: normal;
    margin-bottom: 30px;
    width: 90%;
}

.homepage__oursolutions__item__advantage {
    font-size: 16px;
    color: #161615;
    font-weight: bold;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.homepage__oursolutions__item__advantage span {
    color: #A46648;
}

.homepage__oursolutions__item__btn {
    font-size: 16px;
    font-weight: bold;
    color: #A46648;
    margin-top: 32px;
    display: flex;
    gap: 15px;
    align-items: center;
    cursor: pointer;
}

.homepage__oursolutions__item__btn:hover {
    text-decoration: underline;
}

.homepage__oursolutions__content__image__one {
    height: 260px;
    position: absolute;
    top: -50px;
    left: 35%;
    transform: translateX(-50%);
}

.homepage__oursolutions__content__image__two {
    height: 260px;
    position: absolute;
    top: 10px;
    left: 65%;
    transform: translateX(-50%);
}

@media (max-width: 1450px) {
    .homepage__oursolutions__header {
        width: auto;
        padding: 0 50px;
    }

    .homepage__oursolutions__content__header {
        margin-bottom: 50px;
    }

    .homepage__oursolutions__content__container {
        flex-flow: column nowrap;
        align-items: center;
        gap: 100px;
    }

    .homepage__oursolutions__content__item {
        width: 400px;
        max-width: 90%;
    }
}

@media (max-width: 768px) {
    .homepage__oursolutions__content__container {
        flex-flow: column nowrap;
        align-items: center;
    }

    .homepage__oursolutions__content__item {
        width: 400px;
        max-width: 90%;
    }

    .homepage__oursolutions__content__image__one,
    .homepage__oursolutions__content__image__two {
        margin-top: 25px;
        height: 200px;
    }
}