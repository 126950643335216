.cookieBanner__container {
    position: fixed;
    bottom: 0;
    background-color: #333;
    color: #fff;
    padding: 15px 15.625vw;
    z-index: 1000;
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
}

.cookieBanner__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.cookieBanner__text {
    flex: 0 0 70%;
    max-width: 70%;
    text-align: left;
}

.cookieBanner__text p {
    margin-right: 35px;
}

.cookieBanner__buttons {
    flex: 0 0 30%;
    max-width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 15px;
}

.cookieBanner__container a {
    text-decoration: none;
    color: #A46648;
}

.cookieBanner__container a:hover {
    text-decoration: underline;
}

.cookie-button {
    padding: 10px 20px;
    background-color: #a46648;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 100%;
    max-width: 250px;
    white-space: nowrap;
}

.cookie-button:hover {
    background-color: #724732;
}

.cookie-button-secondary {
    background-color: #555;
}

.cookie-button-secondary:hover {
    background-color: #777;
}

.cookie-button-danger {
    background-color: rgb(121, 18, 18);
}

.cookie-button-danger:hover {
    background-color: rgb(97, 15, 15);
}

.checkbox-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
    width: 250px;
}

.switch-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 20px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #a46648;
}

input:checked+.slider:before {
    transform: translateX(14px);
}

@media (max-width: 1250px) {
    .cookieBanner__container {
        padding: 15px 7.8125vw;
    }
}

@media (max-width: 768px) {
    .cookieBanner__container {
        font-size: 14px;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

    .cookieBanner__content {
        flex-direction: column;
        width: 100%;
    }

    .cookieBanner__text {
        max-width: 100%;
        margin-bottom: 15px;
    }

    .cookieBanner__text p {
        margin-right: 0;
    }

    .checkbox-container {
        width: 100%;
    }

    .cookieBanner__buttons {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 100%;
        margin: 0;
    }

    .cookie-button {
        width: auto;
        max-width: none;
    }
}

@media (max-width: 460px) {
    .cookieBanner__buttons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        gap: 10px;
        width: 100%;
        justify-content: center;
    }

    .cookie-button:nth-child(3) {
        grid-column: 1 / -1;
        width: 100%;
    }

    .cookie-button {
        text-align: center;
        white-space: nowrap;
    }
}