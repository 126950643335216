
.Dashboard-Container {
    color: white;
    font-family: 'Sora', sans-serif;
    color: #636161;
    overflow: hidden;
}

.Dashboard-Body-Container {
    display: flex;

    min-height: calc(100vh - 75px);

    background-color: rgb(217, 217, 217);

    flex-flow: row nowrap;

}

.changesMadeWithoutSaving-Container {
    width: 30%;
    height: 20%;

    background-color: white;
    color: black;

    font-family: 'Sora', sans-serif;
    font-weight: bold;
    font-size: 1.3rem;

    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-flow: column nowrap;

    justify-content: space-evenly;

    border-radius: 25px;

}

.neutral-button {
    background-color: #ffffff;
    color: #333333;
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.neutral-button:hover {
    background-color: #f2f2f2;
    color: #333333;
    border-color: #999999;
}

.neutral-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px #cccccc; /* Example focus style */
}

.block-interactions {
    pointer-events: auto;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Higher z-index to ensure it's on top of other elements */
    pointer-events: auto; /* Prevent user interactions */
}