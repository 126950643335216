.newsManager__body {
    color: #161615;
    font-size: 14px;
    font-family: 'Roc Grotesk', sans-serif;
    padding: 40px;
}

.newsManager__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}

.newsManager__header--narrow {
    max-width: 800px;
    margin: 0 auto;
}

.newsManager__title {
    font-size: 26px;
    margin: 0;
}

.newsManager__add-button {
    background-color: #2d3237;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 20px;
    font-size: 15px;
}

.newsManager__add-button:hover {
    background-color: #0056b3;
}
