.Dashboard-Navbar-Adjustables-DarkMode-Slider-Container {
    width: 100px;
    height: 40px;

    position: relative;
}

.moon, .sun {
    height: 100%;
    cursor: pointer;
    transition: transform 0.4s, opacity 0.2s; /* Add transition effect */
    position: absolute;
    z-index: 1;
}

.moon {
    opacity: 0;
}

/* Moved state for moon image */
.moon.moved {
    transform: translateX(40px); /* Move moon 100px to the right */
    opacity: 100;
}

/* Moved state for moon image */
.sun.moved {
    transform: translateX(40px); /* Move moon 100px to the right */
    opacity: 0;
}

.Dashboard-Navbar-Adjustables-DarkMode-Slider {
    cursor: pointer;
    position: absolute;
    top: 12.5px;
    left: 10px;
    width: 60px;
    height: 15px;
    border-radius: 25px;
    z-index: 0;
    transition: background-color 0.4s;
}

.Dashboard-Navbar-Adjustables-DarkMode-Slider.dark {
    background-color: #6C6C6C;
}

.Dashboard-Navbar-Adjustables-DarkMode-Slider.light {
    background-color: #FFB300;
}
