.newsPage__body {
    background-color: #ffffff;
    color: #161615;
    font-size: 14px;
    font-family: 'Roc Grotesk', sans-serif;
    padding: 4.2328vh 7.8125vw;
}

.newsPage__item-link {
    text-decoration: none;
}

.newsPage__item {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    border-bottom: 1px solid rgba(164, 102, 72, 0.2);
    padding: 20px 0;
    cursor: pointer;
}

.newsPage__image {
    width: 300px;
    height: 175px;
    object-fit: contain;
    flex-shrink: 0;
}

.newsPage__details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.newsPage__date {
    font-weight: bold;
    font-size: 18px;
    color: #A46648;
    margin: 0;
}

.newsPage__shortDescription {
    color: #333333;
    font-size: 20px;
    margin: 5px 0;
    line-height: 1.5;
    font-weight: 700;
}

.newsPage__longDescription {
    color: #333333;
    margin: 5px 0;
    line-height: 1.5;
    font-size: 14px;
}

.newsPage__hashtags {
    color: #A46648;
    font-size: 15px;
    margin-top: 5px;
}

.newsPage__item:hover {
    background-color: #f5f5f5;
}

@media (max-width: 768px) {
    .newsPage__item {
        flex-direction: column;
    }

    .newsPage__image {
        width: 100%;
    }
}
