.GraphContainer {
    width: 100%;
    height: 100%;

    display: flex;

}

.GraphSelector {
    min-width: 300px;
    max-width: 300px;

    border: #3a3a3a solid 2px;
    border-radius: 5px;

    padding: 10px;
    
    display: flex;
    flex-direction: column;
    gap: 10px;

    overflow-y: auto;
    overflow-x: hidden;
}

.GraphSelector.no-scroll {
    overflow: hidden;
}

.GraphSelector::-webkit-scrollbar {
    width: 7.5px;
}

.GraphSelector::-webkit-scrollbar-thumb {
    background: #666666; 
    border-radius: 10px;
}

.GraphSelector::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

.GraphDisplay {
    margin-left: 5px;
    width: 100%;
    height: calc(100% -5px);
    border-radius: 5px;
}

.GraphInfo {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
}

.PopupText {
    display: none;
    position: absolute;
    top: 30px;
    right: 0;
    width: 200px;
    background-color: rgb(29, 27, 27);
    border: 1px solid #353535;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 20;
}

.PopupTextBlack {
    display: none;
    position: absolute;
    top: 30px;
    right: 0;
    width: 200px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 20;
}

.GraphInfo:hover .PopupText {
    display: block;
}

.GraphSelector.infoHoveredDarkmode {
    background-color: #353535;
}

.GraphSelector.infoHovered {
    background-color: #bbb7b7;
}

.GraphInfo:hover .PopupTextBlack {
    display: block;
}

.GraphDisplay-Date-Container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin-right: 90px;
    margin-top: 5px;
}

.GraphDisplay-Date-Category-Container {
    height: 30px;
    width: 60px;
    border-radius: 10px;

    font-weight: 500;
    margin-right: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.GraphDisplay-Date-Date-Container {
    height: 30px;
    width: 175px;
    border-radius: 10px;

    font-weight: 500;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.GraphDisplay-Date-Changer {
    height: 60%;
    width: 15px;

    cursor: pointer;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
}

.GraphDisplay-Date-Changer-Bar {
    width: 12px;
    height: 3px;
    border-radius: 2px;
}

.GraphDisplay-Date-Changer-Bar.Top.Left {
    transform-origin: right center;
    transform: rotate(-45deg);
}

.GraphDisplay-Date-Changer-Bar.Bottom.Left {
    transform-origin: right center;
    transform: rotate(45deg);
}

.GraphDisplay-Date-Changer-Bar.Top.Right {
    transform-origin: left center;
    transform: rotate(45deg);
}

.GraphDisplay-Date-Changer-Bar.Bottom.Right {
    transform-origin: left center;
    transform: rotate(-45deg);
}