.newsList__body {
    color: #161615;
    font-size: 14px;
    font-family: 'Roc Grotesk', sans-serif;
}

.newsList__item {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    border-bottom: 1px solid rgba(164, 102, 72, 0.2);
    padding: 20px 0;
    position: relative;
}

.newsList__image {
    width: 250px;
    height: 150px;
    object-fit: contain;
    flex-shrink: 0;
}

.newsList__details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.newsList__date {
    font-weight: bold;
    font-size: 16px;
    color: #A46648;
    margin: 0;
}

.newsList__shortDescription {
    color: #333333;
    font-size: 18px;
    margin: 5px 0;
    line-height: 1.3;
    font-weight: 700;
}

.newsList__longDescription {
    color: #333333;
    margin: 5px 0;
    line-height: 1.3;
    font-size: 14px;
}

.newsList__hashtags {
    color: #A46648;
    font-size: 13px;
    margin-top: 5px;
}
.newsList__actions {
    display: flex;
    gap: 10px; /* Ruimte tussen knoppen */
    position: absolute;
    right: 20px; /* Helemaal rechts uitlijnen */
    top: 20px; /* Uitlijnen met de bovenkant */
}

.newsList__icon-button {
    background: none;
    border: none;
    margin: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #2d3237;
    transition: color 0.2s ease;
}

.newsList__icon-button:hover {
    color: #0056b3;
}
