.bodyPage__body {
    padding: 50px 175px;
    color: #161615;
    font-family: 'Roc Grotesk', sans-serif;
    font-size: 18px;
}

.bodyPage__content {
    display: flex;
    align-items: stretch;
    gap: 20px;
}

.bodyPage__image {
    flex: 0 0 auto;
    max-width: 33%;
}

.bodyPage__image img {
    width: 100%;
    height: auto;
    max-height: 35vh;
}

.bodyPage__image-fullwidth {
    width: 100%;
    margin-top: 20px;
}

.bodyPage__image-fullwidth img {
    width: 100%;
    height: auto;
}

.bodyPage__header {
    font-size: 48px;
    color: #A46648;
    margin: 0;
    padding: 0;
}

.bodyPage__text h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #A46648;
}

.bodyPage__text p {
    margin: auto 0;
    padding: 0 0 10px 0;
    line-height: 1.6;
}

.bodyPage__points-item {
    margin: 15px 0;
    padding: 10px;
    border: 1px solid #A46648;
    background-color: #EBE3DF;
}

.bodyPage__points-item p {
    margin-top: 5px;
}

.bodyPage__text ul {
    list-style-type: none;
    padding: 0;
    margin-left: 0;
}

.bodyPage__text ul li {
    margin-bottom: 10px;
    position: relative;
    padding-left: 30px;
}

.bodyPage__text ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(50%);
    width: 12px;
    height: 12px;
    background-color: #A46648;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.bodyPage__graph-section {
    max-width: 70%;
    height: 100%;
    flex-grow: 1;
    background-color: rgba(22, 22, 21, 0.65);
}

.bodyPage__graph {
    width: 100%;
    height: 37vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .bodyPage__body {
        padding: 20px;
        font-size: 14px;
    }

    .bodyPage__header {
        font-size: 36px;
        text-align: center;
        margin-bottom: 20px;
    }

    .bodyPage__section {
        flex-direction: column;
    }

    .bodyPage__content {
        flex-direction: column;
    }

    .bodyPage__image {
        width: 100%;
        height: auto;
        margin-top: 10px;
    }

    .bodyPage__text {
        width: 100%;
        padding: 0;
    }
}