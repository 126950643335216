.homepage__headerbody__svg {
    font-family: 'Roc Grotesk';
    margin-bottom: -8px;
}

.homepage__headerbody__path {
    fill: none;
    stroke: #a46648;
    stroke-width: 3;
}

.homepage__headerbody__circle {
    fill: #a46648;
}

.homepage__headerbody__rect {
    opacity: 40%;
    transition: filter 0.3s ease-in-out, border 0.5s ease, opacity 0.3s ease;
    cursor: pointer;
}

.homepage__headerbody__rect.highlighted {
    opacity: 100%;
    filter: drop-shadow(0 0 30px #a46648);
    border: 6px solid #a46648;
}

.homepage__headerbody__title {
    font-size: 36px;
    font-weight: bold;
    line-height: 1;
    transition: opacity 0.3s ease;
    color: #606060;
}

.homepage__headerbody__title.highlighted {
    color: #a46648;
    opacity: 100%;
}

.homepage__headerbody__description {
    font-size: 18px;
}

.homepage__headerbody__description.highlighted {
    color: #cbcbcb;
    opacity: 100%;
}

.homepage__headerbody__description {
    color: #606060;
    opacity: 40%;
}

.homepage__headerbody__link {
    margin-top: 15px;
}

.homepage__headerbody__link a {
    color: #606060;
    text-decoration: none;
}

.homepage__headerbody__link a:hover {
    color: #a46648;
}

.homepage__headerbody__link.highlighted a {
    color: #a46648;
    text-decoration: none;
}

.homepage__headerbody__link.highlighted a:hover {
    text-decoration: underline;
}

@media (max-width: 1300px) {
    .homepage__headerbody__responsive-container {
        display: flex;
        flex-flow: column nowrap;
        gap: 75px;
        align-items: center;
        margin-bottom: 20px;
        padding: 0 10vw;
    }

    .homepage__headerbody__responsive-box {
        background-color: rgba(22, 22, 21, 0.65);
        display: flex;
        flex-flow: column nowrap;
        gap: 10px;
        padding: 25px;
        text-align: center;
        max-width: 750px;
    }

    .homepage__headerbody__responsive-box .homepage__headerbody__title {
        font-size: 36px;
        color: #a46648;
        font-weight: bold;
        line-height: 1;
    }

    .homepage__headerbody__responsive-box .homepage__headerbody__description {
        font-size: 18px;
        color: #cbcbcb;
        opacity: 100%;
    }

    .homepage__headerbody__responsive-box .homepage__headerbody__link {
        font-size: 14px;
    }

    .homepage__headerbody__responsive-box .homepage__headerbody__link a {
        color: #a46648;
    }

    .homepage__headerbody__responsive-box .homepage__headerbody__link a:hover {
        text-decoration: underline;
    }

    .homepage__headerbody__responsive-box .homepage__headerbody__title {
        font-size: 28px;
    }

    .homepage__headerbody__responsive-box .homepage__headerbody__description {
        font-size: 16px;
    }
}