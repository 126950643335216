.oplossingenPage__body {
    background-color: #ffffff;
    color: #161615;
    font-size: 14px;
    font-family: 'Roc Grotesk';
}

.oplossingenPage__grid {
    padding: 3.1746vh 7.8125vw;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3.1746vh;
}

.oplossingenPage__link {
    text-decoration: none;
    color: inherit;
    display: block;
}

.oplossingenPage__item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border: 1px solid #00000029;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    height: 100%;
    box-sizing: border-box;
}

.oplossingenPage__details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.oplossingenPage__item img {
    width: 100%;
    max-height: 300px;
    object-fit: contain;
}

.oplossingenPage__title {
    font-weight: bold;
    font-size: 20px;
    color: #A46648;
    margin: 10px 0;
}

.oplossingenPage__text {
    color: #333333;
    line-height: 1.75;
    margin: 0 0 15px 0;
    font-size: 16px;
}

.oplossingenPage__advantages {
    color: #A46648;
    font-size: 14px;
}

.oplossingenPage__advantages div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.oplossingenPage__advantages span {
    font-size: 18px;
    margin-right: 8px;
}

@media (max-width: 1200px) {
    .oplossingenPage__grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 800px) {
    .oplossingenPage__grid {
        grid-template-columns: 1fr;
    }
}

.oplossingenPage__link:hover .oplossingenPage__item {
    transform: scale(1.05);
    transition: transform 0.3s ease;
}
