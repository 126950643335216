.Dashboard-Flows-Container {
    position: relative;

    width:  100%;
    height: 100%;

    display: flex;
    justify-content: space-between;
}

.Dashboard-Flows-Element-Container {
    width: 100px;
    height: 100px;

    border: white solid 5px;
    border-radius: 100%;
    
    position: relative;
}

.Dashboard-Flows-Element-Row {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
}


.Dashboard-Flows-Element-Container.SOLAR {
    border-color: #DABB50;
}

.Dashboard-Flows-Element-Container.GRID {
    border-color: #F88C8C;
}

.Dashboard-Flows-Element-Container.HOME {
    border-color: #CE80EA;
}

.Dashboard-Flows-Element-Container.BATTERY {
    border-color: #65D8A0;
}

.Dashboard-Flows-SVG {
    position: absolute;
}