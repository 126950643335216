.eboiler__container {
    max-width: 900px;
    margin: 0 auto;
    padding: 30px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
}

.eboiler__image {
    width: 100%;
    max-width: 900px;
    display: block;
    margin: 0 auto 20px;
}

.eboiler__header {
    text-align: center;
    padding: 40px 20px;
    background-color: #A46648;
    color: white;
    margin-bottom: 30px;
}

.eboiler__title {
    font-size: 32px;
    margin-bottom: 10px;
}

.eboiler__subtitle {
    font-size: 18px;
}

.eboiler__section,
.eboiler__benefits,
.eboiler__event {
    padding: 20px;
    margin-bottom: 20px;
}

.eboiler__section {
    background-color: #f8f8f8;
}

.eboiler__benefits {
    background-color: #A46648;
    color: white;
}

.eboiler__benefits .eboiler__section-title {
    color: white;
}

.eboiler__section-title {
    font-size: 24px;
    color: #A46648;
}

.eboiler__text {
    font-size: 18px;
}

.eboiler__list {
    font-size: 18px;
    padding-left: 20px;
    list-style-type: none;
}

.eboiler__event {
    background-color: #f3f3f3;
    border-left: 5px solid #A46648;
    text-align: center;
}

.eboiler__button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #A46648;
    color: white;
    text-decoration: none;
    font-size: 18px;
    border-radius: 5px;
    margin-top: 10px;
}

.eboiler__button:hover {
    background-color: #8a5337;
}