@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.settings {
    width: 100%;

    display: flex;
    flex-flow: column nowrap;
}

.settings__header {
    width: 100%;
    height: 60px;

    display: flex;
    align-items: center;
}

.settings__header__title {
    font-weight: bold;
    font-size: 1.2rem;
    margin-left: 25px;
}

.settings__content {
    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: row nowrap;
}

.settings__pages {
    width: 250px;
    height: 100%;
    
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.settings__page {
    font-weight: bold;
    font-size: 0.9rem;

    width: 80%;
    padding: 10px;

    cursor: pointer;

    transition: background-color 0.3s ease, color 0.3s ease;
}

.settings__page:first-child {
    margin-top: 20px;
}

.settings__page--active--lightmode {

    color: #3348FF;
    background-color: #d0e3fd;
    border-radius: 5px;
}

.settings__page--active--darkmode {

    color: #7AD3FF;
    background-color: #0C186E;
    border-radius: 5px;
}



.settings__page__content {
    width: calc(100% - 250px);
    height: 100%;
}

.settings__page__item {
    width: 100%;
    
    padding: 15px 0px;

    border-bottom: 1px solid #DCE0E5;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.settings__page__items {
    width: 50%;
    height: 100%;

    margin-left: 20px;
    margin-top: 30px;
}

.settings__page__item__title {
    font-weight: bold;
    margin-bottom: 7.5px;
}

.settings__page__item__desc {
    color: #727272;
    margin-bottom: 2px;
}

.settings__page__item--image {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    object-fit: cover;
}

.settings__page__item__edit {

    padding: 5px 10px;

    border-radius: 7.5px;

    border: 2px solid #DCE0E5;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.settings__page__item__save {
    padding: 5px 10px;
    border-radius: 7.5px;

    border: 2px solid #C3FFC9;

    background-color: #caf7b583;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.settings__page__item__cancel {
    padding: 5px 10px;

    background-color: #f7b5b59a;

    border-radius: 7.5px;

    border: 2px solid #FFC3C3;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.settings__page__item__edit--editing {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
}

.settings__page__relais__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.settings__page__relais__header__dropdown--inactive--lightmode {
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-right: 8px solid #000;
    border-bottom: 4px solid transparent;
}

.settings__page__relais__header__dropdown--inactive--darkmode {
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-right: 8px solid #fff;
    border-bottom: 4px solid transparent;
}

.settings__page__relais__header__dropdown--active--lightmode {
    width: 0;
    height: 0;
    border-top: 8px solid #3348FF;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.settings__page__relais__header__dropdown--active--darkmode {
    width: 0;
    height: 0;
    border-top: 8px solid #7AD3FF;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.settings__page__relais__content {
    display: flex;
}

.settings__page__relais__line {
    width: 2px;

    background-color: rgba(100, 100, 100, 0.5);
}

.settings__page__relais__item {
    margin-top: 10px;
    gap: 5px;

    width: 100%;

    opacity: 0.5;

    display: flex;
    align-items: center;
}

.settings__page__relais__item.active--lightmode {
    color: #3348FF;
    opacity: 1;
}

.settings__page__relais__item.active--darkmode {
    color: #7AD3FF;
    opacity: 1;
}

.settings__page__relais__item.inactive--lightmode {
    color: black;
    opacity: 0.5;
}

.settings__page__relais__item.inactive--darkmode {
    color: rgb(177, 177, 177);
    opacity: 0.5;
}

.settings__page__relais__item__preline {
    height: 2px;
    width: 15px;
    
    background-color: rgba(100, 100, 100);
}

.settings__page__relais__item__preline.active {
    background-color: #3348FF;
}

.settings__page__relais__item__preline.inactive { 
    background-color: rgba(100, 100, 100);
}

.settings__page__relais__line--active {
    width: 2px;

    background-color: #3348FF;
}

.settings__page__item__input {
    padding: 5px 10px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #f8f8f8;
    transition: border-color 0.3s ease;
}

.settings__page__item__input:focus {
    outline: none;
    border-color: #66afe9;
    box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
}

.settings__page__item__radio__selection {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 10px;
}

.settings__page__item__radio__card--lightmode {
    width: 250px;

    border-radius: 10px;
    border: solid #DCE0E5 2px;

    transition: border 0.3s ease-in-out;

    cursor: pointer;

    box-shadow: 0px 1px 2px 0px #8080801e;
}

.settings__page__item__radio__card--darkmode {
    width: 250px;

    border-radius: 10px;
    border: solid #DCE0E5 2px;

    transition: border 0.3s ease-in-out;

    cursor: pointer;

    box-shadow: 0px 1px 2px 0px #8080801e;
}


.settings__page__item__radio__card--lightmode.selected {
    color: black;
    text-decoration: none;
    border: solid #4488db 2px;
    background-color: #F0F7FF;
}

.settings__page__item__radio__card--darkmode.selected {
    color: white;
    text-decoration: none;
    border: solid #4488db 2px;
    background-color: #F0F7FF;
}

.settings__page__item__radio__selector {
    width: 10px;
    height: 10px;

    border-radius: 100%;
    border: #3348FF 1px solid;
}

.settings__page__item__radio__header {
    font-weight: 700;
    font-family: "Inter";
    color: #14181F;
    height: 35px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.settings__page__item__radio__desc {
    color: #6F7C8E;
    font-family: 'Inter';
    font-weight: 400;
}

.settings__page__item__radio__card__content {
    padding: 10px 15px 20px 15px;
}

.settings__page__item__radio__button {
    width: 20px;
    height: 20px;

    border-radius: 100%;

    background-color: #90909014;

    border: #B6C1CA 2px solid;
    box-sizing: content-box;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
}


.settings__page__item__radio__button.hovered {
    border: #B6C1CA 2px solid;
    box-shadow: 0px 0px 0px 1px #FFFFFF66,
    0px 0px 0px 2px #3348FF;
}

.settings__page__item__radio__button.selected {
    border: #3348FF 2px solid;
}

.settings__page__item__radio__button__selected {
    width: 14px;
    height: 14px;

    border-radius: 100%;
    background-color: #3348FF;
}

.settings__page__item__create {
    padding: 7.5px 15px;

    border-radius: 7.5px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    border: none;

    background-color: #3348FF;
    color: white;
    box-shadow: 0px -2px 2px 0px #3030301A inset, 0px 12px 12px 0px #FFFFFF1F inset;


    font-family: Inter;
    font-weight: 500;
}

.settings__page__item__input__error {
    color: #DF0C3D;

    display: flex;
    gap: 5px;
    align-items: center;
    margin-top: 5px;

    font-weight: 600;
    font-family: "Inter";
}

.settings__page__item__work-in-progress--lightmode {
  margin-top: 20px;
  padding: 15px;
  background-color: #fff5f5;
  border: 1px solid #ffcdd2;
  border-radius: 8px;
  text-align: center;
  color: #c62828;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.settings__page__item__work-in-progress--darkmode {
    margin-top: 20px;
    padding: 15px;
    background-color: #232323;
    border: 1px solid #ffcdd2;
    border-radius: 8px;
    text-align: center;
    color: #c62828;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }