.Dashboard-Body-Overview-Row-Element-Battery-Object-Filling {
    border-radius: 10px;
}

.Dashboard-Body-Overview-Row-Element-Battery-Data-Graph-Container {
    font-family: "JetBrains";
    font-weight: bold;
    margin-top: 10px;

    display: flex;
    flex-flow: column nowrap;

    white-space: nowrap;

    font-size: 1.05rem;
}   

.Dashboard__BatteryStats__Details__List {
    margin: 5px 0px 10px 0px;
    opacity: 0.50;

    display: flex;

    padding-left: 20px;
}