.DayAheadPriceChartBackBtn:hover,
.DayAheadPriceChartForwardBtn:hover {
    cursor: pointer;
}

.DayAheadPriceChartContainer {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    width: 100%;
    height: 100%;
}

.DayAheadPriceChartResponsiveContainer {
    width: 100%;
    height: 100%;
}

.DayAheadPriceChartGrid {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 40px;
}

.DayAheadPriceChartButtonContainer {
    display: flex;
    align-items: center;
    padding-right: 5px;
}

.DayAheadPriceChartDate {
    color: white;
    padding: 2px 5px;
    text-align: center;
}

.DayAheadPriceChartForwardBtn,
.DayAheadPriceChartBackBtn {
    background: none;
    border: none;
}

.DayAheadPriceChartIcon {
    width: 20px;
    height: 20px;
}

.DayAheadPriceChartForwardBtnIcon,
.DayAheadPriceChartBackBtnIcon {
    color: white;
}

.DayAheadPriceChartTooltipContent {
    background-color: white;
    color: #333;
}

.DayAheadPriceChartTooltipItem {
    color: #333;
}