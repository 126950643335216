.sectoren__body {
    padding: 50px 175px;
    display: flex;
    flex-direction: column;
    gap: 100px;
    color: #161615;
    font-family: 'Roc Grotesk';
}

.sectoren__header {
    font-size: 48px;
    color: #A46648;
    margin: 0;
    padding: 0;
    margin-bottom: -50px;
}

.sectoren__section {
    display: flex;
    align-items: center;
    gap: 50px;
}

.sectoren__image {
    width: 33%;
    text-align: center;
}

.sectoren__image img {
    max-height: 500px;
}

.sectoren__text {
    width: 66%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sectoren__text--brown {
    padding: 50px 175px;
    background: #EBE3DF 0% 0% no-repeat padding-box;
    color: #A46648;
    margin-left: -175px;
}

.sectoren__text h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #A46648;
}

.sectoren__text p {
    margin: auto 0;
    padding: 0 0 10px 0;
    font-size: 18px;
    line-height: 1.6;
}

.sectoren__text ul {
    list-style-type: none;
    padding: 0;
    margin-left: 0;
}

.sectoren__text ul li {
    font-size: 18px;
    margin-bottom: 10px;
    position: relative;
    padding-left: 30px;
}

.sectoren__text ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 12px;
    height: 12px;
    background-color: #A46648;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
    .sectoren__body {
        padding: 20px;
        gap: 50px;
    }

    .sectoren__header {
        font-size: 36px;
        text-align: center;
        margin-bottom: 20px;
    }

    .sectoren__section {
        flex-direction: column;
        gap: 20px;
    }

    .sectoren__image {
        width: 100%;
        text-align: center;
    }

    .sectoren__image img {
        width: 100%;
        max-height: none;
    }

    .sectoren__text {
        width: 100%;
        padding: 0;
    }

    .sectoren__text--brown {
        margin-left: 0;
        padding: 20px;
    }

    .sectoren__text h2 {
        font-size: 20px;
    }

    .sectoren__text p,
    .sectoren__text ul li {
        font-size: 16px;
    }
}