.adminDashboard__app-container {
    display: flex;
    height: 100vh;
    font-family: 'Roc Grotesk';
}

.adminDashboard__sidebar {
    width: 200px;
    min-width: 200px;
    background-color: #2d3237;
    color: #D9D9D9;
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
}

.adminDashboard__logo {
    width: 100%;
    display: flex;
    justify-content: center;
}

.adminDashboard__sidebar h2 {
    font-size: 1.5em;
    border-bottom: 1px solid #D9D9D9;
    text-align: center;
    color: #fff;
    padding: 10px 0;
    margin: 0;
}

.adminDashboard__list {
    padding-bottom: 45px;
}

.adminDashboard__list__sub-title {
    padding-bottom: 2px;
    border-bottom: 1px solid #D9D9D9;
    margin: 0;
}

.adminDashboard__list-item {
    padding-top: 15px;
    padding-left: 10px;
    cursor: pointer;
}

.adminDashboard__list-item--active {
    color: #fff;
    font-weight: bold;
}

.adminDashboard__icon {
    margin-right: 10px;
}

.adminDashboard__logout {
    position: absolute;
    bottom: 10px;
    text-align: center;
}

.adminDashboard__logout-button {
    background-color: #2d3237;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
}

.adminDashboard__main-content {
    flex-grow: 1;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
}

.adminDashboard__user-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid #7F7F7F;
    padding: 20px 50px 10px;
}

.adminDashboard__image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
}

.adminDashboard__username {
    font-weight: bold;
    font-size: 1.5em;
}

.adminDashboard__role {
    color: #B3B3B3;
    font-weight: 600;
    font-size: 0.95em;
}

.adminDashboard__body {
    flex-grow: 1;
    overflow-y: auto;
}

.adminDashboard__dashboard {
    padding: 50px 125px;
}

.adminDashboard__welcome-text {
    text-align: center;
    margin-bottom: 20px;
}

.adminDashboard__welcome-text h1 {
    margin: 0;
    font-size: 2em;
}

.adminDashboard__welcome-text h1:last-child {
    padding-bottom: 30px;
}

.adminDashboard__content {
    width: 100%;
}

.adminDashboard__table-user {
    width: 100%;
    border-collapse: collapse;
}

.adminDashboard__table-user th,
.adminDashboard__table-user td {
    padding: 10px;
    border-bottom: 1px solid #7F7F7F;
    text-align: center;
    color: #7F7F7F;
}

.adminDashboard__table-user th {
    font-weight: bold;
}

.adminDashboard__error__not__found--lightmode {
    background-color: #ffebee;
    font-weight: bold;
    padding: 20px;
    border: 2px solid #c62828;
    border-radius: 5px;
}

.adminDashboard__table__btn {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    text-decoration: none;
    display: inline-block;
    font-family: 'Roc Grotesk';
    font-weight: bold;
}

.adminDashboard__table__userdashboard__btn {
    background-color: #4CAF50;
    color: white;
}

.adminDashboard__table__userdashboard__btn:hover {
    background-color: #45a049;
}

.adminDashboard__table__installerdashboard__btn {
    background-color: #2196F3;
    color: white;
}

.adminDashboard__table__installerdashboard__btn:hover {
    background-color: #1976D2;
}

.adminDashboard__table__button-disabled {
    background-color: gray;
    color: black;
    opacity: 0.7;
    cursor: not-allowed;
}

.adminDashboard__filter {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.adminDashboard__filter label {
    margin-right: 10px;
    font-weight: bold;
    color: #7F7F7F;
}

.adminDashboard__filter select {
    padding: 5px;
    font-size: 16px;
}

.sorted-asc::after {
    content: ' ▲';
    font-size: 12px;
}

.sorted-desc::after {
    content: ' ▼';
    font-size: 12px;
}

.collapsible-section {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
}

/* Header for Each Collapsible Section */
.collapsible-header {
    background-color: #f5f5f5;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
}

/* Hover Effect on the Header */
.collapsible-header:hover {
    background-color: #e9e9e9;
}

/* Header Title Styling */
.collapsible-header h3 {
    margin: 0;
    font-size: 16px;
}

/* Triangle Styling */
.triangle {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 10px;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    border-color: #333 transparent transparent transparent;
    transition: transform 0.3s;
}

/* Rotates the triangle when collapsed */
.triangle.collapsed {
    transform: rotate(-90deg);
}