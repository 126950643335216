.homePage__controller__container {
    padding: 2.116vh 7.8125vw;
    font-family: 'Roc Grotesk';
    max-width: 1200px;
    margin: 0 auto;
    line-height: 1.6;
    color: #161615;
}

.homePage__controller__container h1,
.homePage__controller__container h2,
.homePage__controller__container h3 {
    color: #A46648;
    margin-bottom: 20px;
}

.homePage__controller__introduction {
    text-align: center;
    padding: 40px 20px;
    background-color: #F0F0F0;
}

.homePage__controller__introduction h1 {
    font-size: 2.5em;
}

.homePage__controller__introduction p {
    font-size: 1.2em;
    margin-top: 20px;
}

.homePage__controller__energy-management {
    margin-top: 40px;
    padding: 20px;
}

.homePage__controller__energy-management h2 {
    font-size: 2em;
}

.homePage__controller__energy-management p {
    margin-top: 10px;
}

.homePage__controller__energy-management h3 {
    font-size: 1.5em;
    margin-top: 30px;
}

.homePage__controller__energy-management ul {
    list-style-type: disc;
    margin-left: 20px;
}

.homePage__controller__energy-management li {
    margin-top: 10px;
}

.homePage__controller__energy-management img {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
}

.homePage__controller__firn-controller {
    margin-top: 40px;
    padding: 20px;
    background-color: #EBE3DF;
}

.homePage__controller__firn-controller h2 {
    font-size: 2em;
}

.homePage__controller__firn-controller ul {
    list-style-type: square;
    margin-left: 20px;
}

.homePage__controller__firn-controller li {
    margin-top: 10px;
}

.homePage__controller__additional-control {
    margin-top: 40px;
    padding: 20px;
}

.homePage__controller__additional-control h2 {
    font-size: 2em;
}

.homePage__controller__additional-control h3 {
    font-size: 1.5em;
    margin-top: 30px;
}

.homePage__controller__additional-control p {
    margin-top: 10px;
}

.homePage__controller__additional-control ul {
    list-style-type: circle;
    margin-left: 20px;
}

.homePage__controller__additional-control li {
    margin-top: 10px;
}

.homePage__controller__content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.homePage__controller__content p {
    flex: 1;
    margin-right: 5vw;
}

.homePage__controller__controllerImage {
    max-width: 300px;
    height: auto;
    padding-right: 5vw;
}

@media (max-width: 768px) {
    .homePage__controller__container {
        padding: 10px;
    }

    .homePage__controller__introduction h1 {
        font-size: 2em;
    }

    .homePage__controller__energy-management h2,
    .homePage__controller__firn-controller h2,
    .homePage__controller__additional-control h2 {
        font-size: 1.8em;
    }

    .homePage__controller__energy-management h3,
    .homePage__controller__firn-controller h3,
    .homePage__controller__additional-control h3 {
        font-size: 1.3em;
    }

    .homePage__controller__content {
        flex-direction: column;
        align-items: center;
    }
    
    .homePage__controller__content p {
        margin-right: 0;
    }
    
    .homePage__controller__controllerImage {
        padding-right: 0;
    }
}