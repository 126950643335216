.request-simulation-container {
    padding: 1rem 15vw;
    margin: 2rem auto;
}

.request-simulation-container h2 {
    margin-bottom: 1rem;
    font-size: 2rem;
    color: #A46648;
}

.request-simulation-form {
    display: flex;
    flex-direction: column;
}

.request-simulation-form div {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
}

.request-simulation-form label {
    flex: 1;
    font-weight: 600;
    color: #333;
}

.request-simulation-form input {
    flex: 2;
    padding: 0.6rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.request-simulation-form .input-error {
    border-color: #e57373;
}

.request-simulation-form span {
    position: absolute;
    right: 10px;
    color: #e57373;
    font-size: 0.9rem;
    margin-left: 0.5rem;
}

.request-simulation-form button {
    width: 100%;
    padding: 0.8rem;
    font-size: 1rem;
    color: #fff;
    background-color: #A46648;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
}

.request-simulation-form button:hover {
    background-color: #a467484c;
    color: #A46648;
}

.error-message {
    color: red;
}

@media (max-width: 850px) {
    .request-simulation-container {
        padding: 1rem 5vw;
    }

    .request-simulation-container h2 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
    }

    .request-simulation-form div {
        flex-direction: column;
        align-items: flex-start;
    }

    .request-simulation-form label {
        flex: none;
        margin-bottom: 0.3rem;
    }

    .request-simulation-form input {
        flex: none;
        width: 100%;
        font-size: 1rem;
        box-sizing: border-box;
    }

    .request-simulation-form span {
        color: #e57373;
        font-size: 0.9rem;
        margin-left: 0.5rem;
    }

    .request-simulation-form button {
        padding: 0.7rem;
        font-size: 0.9rem;
    }
}