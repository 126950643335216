.Dashboard-Navbar-container {
    width: 100vw;
    background-color: #2C2F33;
    height: 75px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.Dashboard-Navbar-User-Image {
    height: 100%;
    border-radius: 100%;
}

.Dashboard-Navbar-User-Image-Container {
    height: 55px;

    margin-right: 10px;
}

.Dashboard-Navbar-User-Details-Container {
    color: white;
}

.Dashboard-Navbar-User-Details-Role {
    font-weight: bold;
    opacity: 0.7;
}

.Dashboard-Navbar-User-Container {
    display: flex;
    flex-flow: row nowrap;

    align-items: center;
    height: 100%;
    margin-left: 20px;
}

.Dashboard-Navbar-Adjustables-DarkMode-Container {
    height: 100%;

    display: flex;
    align-items: center;
}

.Dashboard-Navbar-Adjustables-Container {
    padding-right: 20px;
}

.Dashboard-Navbar-Return-Button {
    height: 50%;

    background-color: #7289DA;
    color: white;
    border: none;
    border-radius: 5px;
    margin: auto;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.Dashboard-Navbar-Return-Button:hover {
    background-color: #5b6eae; /* Darker shade on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.Dashboard-Navbar-Return-Button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(114, 137, 218, 0.5); /* Focus outline */
}

.dashboard__navbar__buttons {
    display: flex;

    align-items: center;
    gap: 40px;
    margin-left: auto;
    margin-right: 40px;
}