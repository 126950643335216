.news-form-container {
    max-width: 800px;
    margin: 0 auto;
}

.news-form-container form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.news-form-container label {
    font-size: 1rem;
    color: #555;
    margin-bottom: 5px;
}

.news-form-container input[type="text"],
.news-form-container textarea,
.news-form-container input[type="file"] {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    transition: border-color 0.3s;
    box-sizing: border-box;
    font-family: inherit;
}

.news-form-container input[type="text"]:focus,
.news-form-container textarea:focus,
.news-form-container input[type="file"]:focus {
    border-color: #007bff;
    outline: none;
}

.news-form-container textarea {
    resize: vertical;
    height: 150px;
}

.news-form-container input[type="file"] {
    padding: 5px;
}

.news-form-container .error-message {
    color: red;
    font-size: 0.9rem;
    text-align: center;
}

.news-form-container button {
    padding: 10px 20px;
    background-color: #2D3237;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.news-form-container button:hover {
    background-color: #0056b3;
}

.news-form-container button:disabled {
    background-color: #8eaecf;
    color: #3a3a3a;
}

.news-form-container input[type="text"].hashtag-input {
    width: calc(25% - 10px);
    margin-right: 10px;
}

.news-form-container input[type="text"].hashtag-input:last-of-type {
    margin-right: 0;
}

.news-form-image-input-group {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    background-color: #fff;
    position: relative;
    transition: border-color 0.3s;
}

.news-form-image-input-group:hover {
    border-color: #2D3237;
}

.news-form-image-input-group select {
    border: none;
    background: transparent;
    padding: 10px;
    font-size: 1rem;
    cursor: pointer;
    outline: none;
    flex: 0 0 100px;
    border-right: 1px solid #ccc;
}

.news-form-image-input-group select option:hover {
    background-color: #2D3237;
    color: #fff;
}

.news-form-image-input-group input[type="file"],
.news-form-image-input-group input[type="url"] {
    border: none;
    flex: 1;
    padding: 10px;
    font-size: 1rem;
    outline: none;
    box-sizing: border-box;
}

.news-form-image-input-group input[type="file"]::file-selector-button {
    display: none;
}

.news-form-image-input-group select,
.news-form-image-input-group input {
    font-family: inherit;
}

.news-form-image-input-container {
    margin-bottom: 15px;
}

.news-form__onderschrift {
    font-size: 0.75rem;
    color: #999;
    margin-top: 5px;
}

@media (max-width: 768px) {
    .news-form-container {
        padding: 15px;
    }

    .news-form-container input[type="text"].hashtag-input {
        width: 100%;
        margin-bottom: 10px;
    }
}
