.GraphSelector-Item-Container {
    width: 100%;
    height: 100px;
    
    border-radius: 25px;
    
    display: flex;
    flex-flow: column nowrap;

    cursor: pointer;

}

.GraphSelector-Item-Container.darkmode:hover {
    background-color: #3a3a3a;
}

.GraphSelector-Item-Container.lightmode:hover {
    background-color: #a0a0a0;
}

.GraphSelector-Item-Title {
    font-size: 1.1rem;
    margin-left: 20px;
    margin-top: 5px;
}

.GraphSelector-Item-Graph {

    width: calc(100% - 40px);
    height: 50px;

    border-left: lightgray 2px solid;
    border-bottom: lightgray 2px solid;

    margin: 10px 0px 10px 20px;
}