.homePage__news-alert__hexagon {
    position: absolute;
    background-color: #f0f0f0;
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    padding: 10px;
}

.homePage__news-alert__hexagon-link {
    width: 92.31px;
    height: 80px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #A46648A6;
}

.homePage__news-alert__hexagon-image {
    width: 196.16px;
    height: 170px;
    top: 110px;
    right: calc(50% - 19px);
    transform: translateX(50%);
    background-color: transparent;
    overflow: hidden;
}

.homePage__news-alert__hexagon-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: inherit;
}

.homePage__news-alert__hexagon-text {
    width: 115.39px;
    height: 100px;
    top: 50px;
    left: calc(50% - 115px);
    transform: translateX(-50%);
    background-color: #A46648A6;
    display: flex;
    flex-direction: column;
}

.homePage__news-alert__hexagon p {
    margin: 0;
    width: 120px;
}

.homePage__news-alert__link {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

.homePage__news-alert__link:hover {
    text-decoration: underline;
}

@media (max-width: 1150px) {
    .homePage__news-alert__hexagon-text {
        display: none;
    }

    .homePage__news-alert__hexagon-link {
        top: -60px;
        left: -45px;
        width: 92.31px;
        height: 80px;
    }

    .homePage__news-alert__hexagon-image {
        top: -15px;
        left: calc(20px - 115px);
        width: 138.46px;
        height: 120px;
    }
}