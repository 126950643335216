.homePage__news__container {
    background-color: #161615;
    padding: calc(200vh / 9.45) 0 calc(200vh / 9.45) calc(150vw / 19.20);
    display: flex;
    box-sizing: border-box;
    margin: 0;
    overflow: hidden;
}

.homePage__news__header {
    max-width: calc(275vw / 19.20);
    min-width: 200px;
    margin-right: calc(100vw / 19.20);
    display: flex;
    flex-direction: column;
}

.homePage__news__subtitle {
    color: #A46648;
    margin: 0 0 16px 0;
    font-size: 14px;
    letter-spacing: 2.5px;
}

.homePage__news__header h1 {
    font-size: calc(34vw / 19.20);
    margin-bottom: 30px;
    margin-top: 0;
    color: #A46648;
}

.homePage__news__text {
    color: #ffffff;
    line-height: 1.75;
    margin: 0 0 15px 0;
    font-size: 16px;
}

.homePage__news__link {
    margin: 0;
    margin-top: auto;
    font-size: 14px;
}

.homePage__news__container a {
    color: #A46648;
    text-decoration: none;
    margin-top: 10px;
    font-weight: bold;
}

.homePage__news__container a:hover {
    text-decoration: underline;
}

.homePage__news__carousel-container {
    width: calc(1245vw / 19.20);
    margin-top: 50px;
    position: relative;
}

.homePage__news__inner-carousel {
    width: 100%;
    height: 400px;
    overflow: hidden;
}

.homePage__news__track {
    display: inline-flex;
    height: 100%;
    transition: transform 0.2s ease-in-out;
}

.homePage__news__card-container {
    width: calc(400vw / 19.20);
    height: 400px;
    flex-shrink: 0;
    padding-right: calc(15vw / 19.20);
}

.homePage__news__card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    box-sizing: border-box;
}

.homePage__news__card img {
    width: 100%;
    height: 50%;
    object-fit: cover;
    margin: 0;
    flex-shrink: 0;
}

.homePage__news__date {
    color: #A46648;
    font-weight: bold;
    font-size: 20px;
    margin: 20px 0 10px 0;
}

.homePage__news__card .homePage__news__text {
    color: #ffffff;
    font-size: 14px;
    margin: 0 25px 0 0;
}

.homePage__news__hashtags {
    color: #A46648;
    margin: 0;
    margin-top: auto;
}

.homePage__news__nav button {
    position: absolute;
    top: 25%;
    transform: translatey(-50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    outline: none;
    border: none;
    cursor: pointer;
    color: #A46648;
    background-color: #161615e0;
}

.homePage__news__nav .homePage__news__prev {
    left: -45px;
    display: none;
}

.homePage__news__nav .homePage__news__prev.show,
.homePage__news__nav .homePage__news__next.show {
    display: block;
}

.homePage__news__nav .homePage__news__next {
    right: -30px;
}

.homePage__news__nav .homePage__news__next.hide {
    display: none;
}

@media (max-width: 1150px) {
    .homePage__news__container {
        padding: calc(100vh / 9.45) calc(105vw / 19.20);
        flex-direction: column;
    }

    .homePage__news__carousel-container {
        width: calc(1710vw / 19.20);
    }

    .homePage__news__card-container {
        width: calc(1650vw / 3 / 19.20);
        padding-right: calc(20vw / 19.20);
    }

    .homePage__news__header {
        max-width: 100%;
        margin-right: 0;
        text-align: center;
    }

    .homePage__news__header h1 {
        font-size: 40px;
    }

    .homePage__news__link {
        margin-top: 10px;
    }
}

@media (max-width: 1024px) {
    .homePage__news__card-container {
        width: calc(1640vw / 2 / 19.20);
        padding-right: calc(35vw / 19.20);
    }
}

@media (max-width: 550px) {
    .homePage__news__container {
        padding: 5vh 5vw;
        flex-direction: column;
    }

    .homePage__news__carousel-container {
        width: 100%;
    }

    .homePage__news__card-container {
        width: 90vw;
        padding-right: 0;
    }

    .homePage__news__nav .homePage__news__prev {
        left: -30px;
        display: none;
    }

    .homePage__news__header h1 {
        font-size: 30px;
    }
}