.batteryPage__container {
    padding: 3vh 8vw;
    font-family: 'Roc Grotesk';
    max-width: 1200px;
    margin: 0 auto;
    color: #161615;
    font-size: 1.1em;
    display: flex;
    flex-direction: column;
}

.batteryPage__container h1,
.batteryPage__container h2 {
    color: #A46648;
    margin-bottom: 20px;
}

.batteryPage__introduction {
    text-align: center;
    padding: 40px 20px;
    background-color: #EBE3DF;
}

.batteryPage__intro-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    text-align: left;
}

.batteryPage__introduction h1 {
    font-size: 2.8em;
}

.batteryPage__introduction p {
    flex: 1;
    font-size: 1.25em;
    margin-top: 20px;
}

.batteryPage__tower-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.batteryPage__tower-text {
    width: 60%;
}

.batteryPage__tower-image {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.batteryPage__tower-image img {
    max-width: 100%;
    height: auto;
}

.batteryPage__installed-content {
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

.batteryPage__installed-tower img {
    width: auto;
    max-height: 50vh;
    object-fit: cover;
}

.batteryPage__single-battery {
    max-width: 35%;
    height: auto;
}

.batteryPage__cabinet-content li,
.batteryPage__tower-text li {
    margin-top: 10px;
}

.batteryPage__installation-and-cabinet {
    display: flex;
    gap: 20px;
    margin-top: 40px;
}

.batteryPage__installation-content {
    display: flex;
    flex-direction: column;
    width: 75%;
}

.batteryPage__cabinet-image {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.batteryPage__cabinet-image img {
    max-width: 100%;
    height: auto;
}

@media (max-width: 950px) {
    .batteryPage__container {
        padding: 10px;
    }

    .batteryPage__container img {
        max-width: 50vw;
        height: auto;
    }

    .batteryPage__introduction {
        padding: 20px 10px;
        text-align: center;
    }

    .batteryPage__intro-content {
        display: block;
        text-align: center;
        margin-bottom: 20px;
    }

    .batteryPage__tower-text {
        width: 100%;
    }

    .batteryPage__single-battery {
        max-width: 80%;
        margin: 0 auto 20px auto;
    }

    .batteryPage__introduction h1 {
        font-size: 2.5em;
    }

    .batteryPage__tower-content {
        flex-direction: column;
    }

    .batteryPage__installed-tower img {
        max-height: 30vh;
        object-fit: cover;
    }

    .batteryPage__installation-and-cabinet {
        flex-direction: column;
        align-items: center;
    }

    .batteryPage__installation-content,
    .batteryPage__cabinet {
        width: 100%;
    }

    .batteryPage__cabinet-image {
        width: 100%;
        margin-top: 20px;
    }
}