.partners-page {
    padding: 40px 7.8125vw;
    text-align: center;
    background-color: #333;
}

.partners-page h1 {
    color: #A46648;
    font-size: 2.5em;
    margin-bottom: 30px;
}

.partners-grid,
.sponsored-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2vw;
}

.partner-card {
    background-color: #fff;
}

@media (max-width: 1200px) {
    .partners-grid,
    .sponsored-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .partners-grid,
    .sponsored-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}

.partner-card {
    padding: 20px;
    border: 1px solid #00000029;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.partner-logo-container {
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.partner-logo {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.partner-card h2 {
    font-size: 1.5em;
    margin: 10px 0;
    color: #333;
}

.partner-card p {
    font-size: 1em;
    color: #666;
}

.partner-link {
    color: #a46648;
    text-decoration: none;
    font-weight: bold;
}

.partner-link:hover {
    text-decoration: underline;
}