/* Containers */
.offerte__container {
    display: block;
    max-width: 100% !important;
    margin: 15px 0;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.offerte__fluid-container {
    width: 100%;
    padding-bottom: 25px;
}

.offerte__container--left-aligned {
    text-align: left;
}

.offerte__bebat-container {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.offerte__bebat-container .offerte__select {
    flex-grow: 1;
    width: auto;
    max-width: none;
}

/* Grid */
.offerte__grid {
    margin: -1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    padding: 0;
}

.offerte__column--four.offerte__column--wide {
    width: 25% !important;
}

.offerte__column--six-wide {
    width: 37.5% !important;
}

.offerte__column--eight-wide {
    width: 50% !important;
}

.offerte__grid>.offerte__column--left-aligned {
    text-align: left;
    align-self: inherit;
}

.offerte__grid>.offerte__column {
    position: relative;
    display: inline-block;
    width: 6.25%;
    padding: 1rem;
    vertical-align: top;
}

.offerte__grid>.offerte__row {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: inherit;
    align-items: stretch;
    width: 100% !important;
    padding: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.offerte__grid--celled>.offerte__row {
    width: 100% !important;
    margin: 0;
    padding: 0;
    box-shadow: 0 -1px 0 0 #d4d4d5;
}

.offerte__grid--equal-width>.offerte__column,
.offerte__grid--equal-width>.offerte__row>.offerte__column {
    display: inline-block;
    flex-grow: 1;
}

.offerte__grid--celled {
    width: 100%;
    margin: 1em 0;
    box-shadow: 0 0 0 1px #d4d4d5;
}

.offerte__grid--equal-width>.offerte__column--wide,
.offerte__grid--equal-width>.offerte__row>.offerte__column--wide {
    flex-grow: 0;
}

.offerte__grid--celled>.offerte__column:first-child,
.offerte__grid--celled>.offerte__row>.offerte__column:first-child {
    box-shadow: none;
}

.offerte__grid--celled>.offerte__column,
.offerte__grid--celled>.offerte__row>.offerte__column {
    padding: 1em;
}

.offerte__grid--celled>.offerte__column,
.offerte__grid--celled>.offerte__row>.offerte__column {
    box-shadow: -1px 0 0 0 #d4d4d5;
}

.offerte__column--center-aligned {
    text-align: center;
}

/* Segments */
.offerte__segment {
    font-size: 1rem;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15);
    margin: 1rem 0;
    padding: 1em 1em;
    border-radius: .28571429rem;
    border: 1px solid rgba(34, 36, 38, .15);
}

.offerte__segment--inverted {
    background: #1b1c1d;
    color: rgba(255, 255, 255, .9);
    border: none;
    box-shadow: none;
}

.offerte__segment:last-child {
    margin-bottom: 0;
}

.offerte__segment--inverted>.offerte__header {
    color: #fff;
}

/* Dividers */
.offerte__divider {
    border-top: 1px solid rgba(34, 36, 38, .15);
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    font-size: 1rem;
    margin: 1rem 0;
    line-height: 1;
    height: 0;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .05em;
    color: rgba(0, 0, 0, .85);
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}